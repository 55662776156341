:root {
  font-family: 'Helvetica Neue', 'YuGothic', 'Yu Gothic', '游ゴシック', 'ヒラギノ角ゴ Pro W3',
    'Hiragino Kaku Gothic Pro', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  font-size: 12px;
  font-feature-settings: 'halt';
}

:root.html2canvas {
  font-feature-settings: normal;
}

body {
  margin: 0;
  padding: 0;
  min-width: 100%;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}